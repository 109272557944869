'use client'

import React from 'react'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker as MaterialDatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useFormContext } from '../Form/FormProvider'
import trackEvent from '../../lib/track-event'

const DatePicker = React.forwardRef(({ onChange, ...rest }, ref) => {
  const { formName, hubspotFormId } = useFormContext()

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MaterialDatePicker
        className="MuiXDatePicker"
        ref={ref}
        onChange={(value) => {
          onChange && onChange(value)

          trackEvent('Form Value Changed', {
            'Form Name': formName,
            'Form Hubspot ID': hubspotFormId,
            'Form Element Name': rest.name,
            'Form Element Type': 'datepicker',
            'New Value': dayjs(value).startOf('day').format('MM/DD/YYYY'),
          })
        }}
        {...rest}
      />
    </LocalizationProvider>
  )
})

DatePicker.displayName = 'DatePicker'

export default DatePicker
