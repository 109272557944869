'use client'

import React from 'react'
import JoyButton from '@mui/joy/Button'
import trackEvent from '../../lib/track-event'
import getTextFromChildren from '../../lib/get-text-from-children'

const Button = React.forwardRef(({ children, fullWidth, forceFullWidth, triggersWhat, ...rest }, ref) => {
  const sxProps = {
    ...rest.sx,
  }

  if (forceFullWidth) {
    sxProps.width = '100%'
  } else if (fullWidth) {
    sxProps.width = { xs: '100%', md: 'auto' }
  }

  return (
    <JoyButton
      {...rest}
      ref={ref}
      sx={sxProps}
      onClick={(event) => {
        Boolean(rest.onClick) && rest.onClick()

        const isInHeader = event.currentTarget.closest('header') !== null
        const isInFooter = event.currentTarget.closest('footer') !== null

        trackEvent('Button Clicked', {
          'Button Text': getTextFromChildren(children),
          'Button Type': rest.type,
          Trigger: rest.href ? 'Link' : triggersWhat,
          Destination: rest.href,
          Target: rest.target,
          Location: isInHeader ? 'Header' : isInFooter ? 'Footer' : 'Body',
        })
      }}
    >
      {children}
    </JoyButton>
  )
})

Button.displayName = 'Button'

export default Button
