'use client'

import JoyModalClose from '@mui/joy/ModalClose'
import trackEvent from '../../lib/track-event'

const ModalClose = ({ children, ...rest }) => {
  return (
    <JoyModalClose
      {...rest}
      onClick={() => {
        Boolean(rest.onClick) && rest.onClick()

        trackEvent('Modal Close Clicked')
      }}
    >
      {children}
    </JoyModalClose>
  )
}

export default ModalClose
