import React from 'react'

/**
 * @param {object} children - the children of the component
 * @return {string} - string containing the text from the children
 */
const getTextFromChildren = (children) => {
  let text = ''

  React.Children.forEach(children, (child) => {
    if (typeof child === 'string') {
      text += ` ${child}`
    } else if (React.isValidElement(child)) {
      text += getTextFromChildren(child.props.children)
    }
  })

  return text
}

export default getTextFromChildren
