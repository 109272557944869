import logger from '@/lib/logger'

/**
 * @param {string} text - string of the text you want to copy to the clipboard
 */
const copyTextToClipboard = async (text) => {
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text)
    }
  } catch (error) {
    logger.error('Failed to copy text to clipboard: ', error)
  }
}

export default copyTextToClipboard
