'use client'

import JoyListItemButton from '@mui/joy/ListItemButton'
import trackEvent from '../../lib/track-event'
import getTextFromChildren from '../../lib/get-text-from-children'

const ListItemButton = ({ children, triggersWhat, ...rest }) => {
  return (
    <JoyListItemButton
      {...rest}
      onClick={(event) => {
        Boolean(rest.onClick) && rest.onClick()

        const isInHeader = event.currentTarget.closest('header') !== null
        const isInFooter = event.currentTarget.closest('footer') !== null

        trackEvent('List Item Button Clicked', {
          'List Item Button Text': getTextFromChildren(children),
          Trigger: rest.href ? 'Link' : triggersWhat,
          Destination: rest.href,
          Target: rest.target,
          Location: isInHeader ? 'Header' : isInFooter ? 'Footer' : 'Body',
        })
      }}
    >
      {children}
    </JoyListItemButton>
  )
}

export default ListItemButton
