'use client'

/**
 * @param {string} eventName - the name of the event that is shown in the analytics dashboard
 * @param {object} data - object containing any meta data about the event
 */
const trackEvent = (eventName, data) => {
  if (typeof window === 'undefined') return // Ensure the code runs only on the client

  const trackWithGTM = () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: eventName,
        ...data,
      })
    } else {
      setTimeout(trackWithGTM, 100)
    }
  }

  trackWithGTM()

  const trackWithHeap = () => {
    if (window.heap) {
      window.heap.track(eventName, { ...data })
    } else {
      setTimeout(trackWithHeap, 100)
    }
  }

  trackWithHeap()
}

export default trackEvent
