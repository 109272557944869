'use client'

import { useState } from 'react'
import { useRouter } from 'next/navigation'
import { storyblokEditable } from '@storyblok/react'
import { useQuery } from '@tanstack/react-query'
import HubspotForm from '@audioeye-marketing/ui/src/components/HubspotForm'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Card from '@audioeye-marketing/ui/src/components/Card'
import trackEvent from '@audioeye-marketing/ui/src/lib/track-event'
import identifyUser from '@audioeye-marketing/ui/src/lib/identify-user'
import colors from '@audioeye-marketing/ui/src/theme/colors'
import parseHtmlString from '@audioeye-marketing/ui/src/lib/parse-html-string'
import getBrowserCookie from '@/lib/get-browser-cookie'
import { useAppContext } from '@/providers/App.jsx'

const Form = ({ blok, card, backgroundColor, onSubmitSuccess }) => {
  const { isEUVisitor } = useAppContext()

  const formId =
    process.env.NEXT_PUBLIC_WWW_APP_ENV !== 'production'
      ? isEUVisitor
        ? blok?.hubspot_form?.stagingHubspotFormIdConsentRequired
        : blok?.hubspot_form?.stagingHubspotFormIdNoConsent
      : isEUVisitor
        ? blok?.hubspot_form?.productionHubspotFormIdConsentRequired
        : blok?.hubspot_form?.productionHubspotFormIdNoConsent

  const { isPending, error, data } = useQuery({
    queryKey: ['hubspotFormData', formId],
    queryFn: async () => await fetch(`/api/forms/${formId}/`).then((res) => res.json()),
    staleTime: 1000 * 60 * 30, // Data will be fresh for 30 minutes
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [submissionError, setSubmissionError] = useState(false)
  const [submissionErrorMessage, setSubmissionErrorMessage] = useState({
    title: 'Having troubles at the moment.',
    body: 'Please try again later.',
  })
  const router = useRouter()

  if (!formId) return null

  // If the form has a Form Name field (typically hidden field) in Hubspot, use that as the form name
  const formNameField = data?.form?.fieldGroups
    ?.flatMap((group) => group.fields)
    ?.find((field) => field?.name === 'form_name')
  const formName = formNameField?.defaultValue || data?.form?.name
  const formLanguage = data?.form?.configuration?.language || 'en'
  const postSubmitAction = data?.form?.configuration?.postSubmitAction

  const cardBackgroundColor = backgroundColor === colors.common.white ? colors.neutral[50] : colors.common.white

  const Wrapper = card ? Card : Box
  const wrapperProps = {
    // * Necessary for Section.jsx color determiner
    className: card ? 'CardWithForm' : undefined,
    size: card ? 'lg' : undefined,
    sx: {
      width: '100%',
      border: 'none',
      backgroundColor: cardBackgroundColor,
      '--joy-palette-background-surface': `${cardBackgroundColor} !important`,
    },
  }

  if (error || submissionError) {
    if (error) {
      trackEvent('API Exception', {
        Label: 'Error with React Query pulling Hubspot form data',
        'Full Exception': error,
      })
    }

    return (
      <Wrapper {...wrapperProps}>
        <Box sx={{ textAlign: 'center' }} {...storyblokEditable(blok)}>
          <Typography level="title-lg">{submissionErrorMessage.title}</Typography>
          <Typography level="body-lg">{submissionErrorMessage.body}</Typography>
        </Box>
      </Wrapper>
    )
  }

  if (isFormSubmitted && !(error || submissionError)) {
    return (
      <Wrapper {...wrapperProps}>
        <Box sx={{ textAlign: 'center' }}>
          {postSubmitAction?.type === 'thank_you' && postSubmitAction?.value ? (
            parseHtmlString(data?.form?.configuration?.postSubmitAction?.value)
          ) : (
            <>
              <Typography level="title-lg">Thanks for reaching out!</Typography>
              <Typography level="body-lg">Someone from the AudioEye team will contact you shortly.</Typography>
            </>
          )}
        </Box>
      </Wrapper>
    )
  }

  return (
    <Wrapper {...wrapperProps}>
      <HubspotForm
        {...storyblokEditable(blok)}
        formId={formId}
        formName={formName} // prefer to use form name from form data, but fallback to the field value if it's not available
        language={formLanguage}
        isLoading={isPending}
        fieldGroups={data?.form?.fieldGroups}
        submitButtonText={data?.form?.displayOptions?.submitButtonText}
        legalConsentOptions={data?.form?.legalConsentOptions}
        isSubmitting={isSubmitting}
        onSubmit={async (submissionData) => {
          setIsSubmitting(true)

          const legalConsent = submissionData?.LEGAL_CONSENT
          delete submissionData.LEGAL_CONSENT
          let legalConsentOptions = {}

          if (legalConsent) {
            legalConsentOptions = {
              consent: {
                consentToProcess: legalConsent?.processing === 'true',
                text: data?.form?.legalConsentOptions?.consentToProcessCheckboxLabel?.replaceAll(/<[^>]*>/gi, ''),
                communications: data?.form?.legalConsentOptions?.communicationsCheckboxes?.map((currentCheckbox) => {
                  return {
                    text: currentCheckbox?.label?.replaceAll(/<[^>]*>/gi, ''),
                    subscriptionTypeId: currentCheckbox?.subscriptionTypeId,
                    value: legalConsent[`subscription_type_${currentCheckbox?.subscriptionTypeId}`] === 'true',
                  }
                }),
              },
            }
          }

          // * A form may have dependent fields, if so, we need to remove them from the submission data if they are not filled out or Hubspot will fail the submission
          const dependentFields = []
          data?.form?.fieldGroups?.forEach((fieldGroup) => {
            fieldGroup?.fields?.forEach((field) => {
              if (field?.dependentFields) {
                dependentFields?.push(field?.name)
              }
            })
          })

          let formData = {
            ...Object.keys(submissionData).reduce((acc, key) => {
              // Include the field if it has a value AND it's not the password field (don't want PII in Hubspot)
              if (submissionData[key] && key !== 'password') {
                acc[key] = submissionData[key]
              }
              return acc
            }, {}),
          }

          let magicLink = ''
          if (formName === 'Free Trial Form') {
            const createAccount = await fetch(
              `${process.env.NEXT_PUBLIC_WWW_AE_MONO_ENDPOINT}/v2/user/create-from-marketing`,
              {
                method: 'POST',
                headers: {
                  'Content-type': 'application/json',
                },
                body: JSON.stringify({
                  firstName: formData?.firstname,
                  lastName: formData?.lastname,
                  email: formData?.email,
                  phone: formData?.phone,
                  company: formData?.company,
                  url: formData?.website,
                  username: formData?.email,
                  password: submissionData?.password,
                  customerType: 'Direct',
                  planId: 'simple-monthly',
                }),
              }
            )

            const accountResponse = await createAccount.json()

            if (createAccount.status === 409) {
              trackEvent(`API Exception`, {
                Label: 'Magic Link Existing User',
                'Full Exception': accountResponse,
              })
              setSubmissionError(true)
              setSubmissionErrorMessage({
                title: 'Existing User',
                body: 'Please sign into your account',
              })
              return
            }

            // possible API 404
            if (createAccount.status === 404) {
              trackEvent(`API Exception`, {
                Label: 'Magic Link 404',
                'Full Exception': accountResponse,
              })
              setSubmissionError(true)
              return
            }

            if (!createAccount.ok) {
              trackEvent(`API Exception`, {
                Label: 'Magic Link Error',
                'Full Exception': accountResponse,
              })
              setSubmissionError(true)
              return
            }

            trackEvent('Account Created', {
              Email: formData.email,
              Website: formData.website || '',
              'Company Name': formData.company || '',
              'Subscription ID': accountResponse.subscriptionId || '',
              'Portal Account ID': accountResponse.accountId || '',
              'Portal User ID': accountResponse.userId || '',
            })

            magicLink = accountResponse.magicLink
            formData.user_id__c = accountResponse.userId
            formData['0-2/portal_account_id'] = accountResponse.accountId
          }

          if (formName === 'Scanner Gate Form') {
            const scanResults = JSON.parse(window.sessionStorage.getItem('scan_results'))

            formData = {
              ...formData,
              ...scanResults,
            }
          }

          try {
            if (!/@e2e.staging-audioeye.com$/.test(formData.email)) {
              const response = await fetch(`/api/forms/submit/${formId}`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  formData,
                  legalConsentOptions,
                  context: {
                    hutk: getBrowserCookie('hubspotutk'),
                    pageUri: window && window?.location?.href,
                    pageName: document?.title || '',
                  },
                }),
              })

              const result = await response.json()
              if (response.ok) {
                if (onSubmitSuccess) {
                  onSubmitSuccess()
                }

                if (result?.responseMessage?.status !== 'error') {
                  identifyUser(formData?.email)

                  trackEvent('Hubspot Form Submitted', {
                    'Hubspot Form Name': formName,
                    'Form Hubspot ID': formId,
                    'Job Title': formData?.jobtitle || '',
                    'Company Name': formData?.company || '',
                    Website: formData?.website || '',
                    'Agency or Freelancer': formData?.agency_or_freelancer__c || '',
                    Email: formData?.email || '',
                  })
                } else {
                  setSubmissionError(true)
                  setIsSubmitting(false)
                }
              } else {
                trackEvent('API Exception', {
                  Label: 'Error with submitting Hubspot form data',
                  'Full Exception': result,
                })

                setSubmissionError(true)
                setIsSubmitting(false)
              }
            }
          } catch (error) {
            trackEvent('API Exception', {
              Label: 'Error with submitting Hubspot form data',
              'Full Exception': error,
            })

            setSubmissionError(true)
            setIsSubmitting(false)
          }

          if (formName === 'Free Trial Form') {
            if (magicLink) {
              router.push(magicLink)
              setIsFormSubmitted(true)
            } else {
              setIsFormSubmitted(true)
            }
          } else if (formName === 'Free Consultation Form') {
            const utmParams = JSON.parse(window.localStorage.getItem('utm_params'))

            if (
              utmParams.utm_campaign !== 'toolbar_referral' &&
              formData?.['education_role'] !== 'Student' &&
              formData?.['education_role'] !== 'Professor'
            ) {
              const revenue = parseInt(formData?.['0-2/annualrevenue']?.replaceAll('$', '')?.replaceAll(',', ''), 10)
              const finalRevenue = Number.isNaN(revenue) ? 0 : revenue
              let redirect = '/schedule-meeting/'

              if (formLanguage !== 'en') {
                redirect = `/${formLanguage}/schedule-meeting/`
              } else if (formData?.agency_or_freelancer__c === 'Yes') {
                redirect = '/schedule-meeting-partner/'
              }

              if (
                formData?.['education_role'] !== 'Faculty / Administration' &&
                formData?.['education_role'] !== 'Other'
              ) {
                if (finalRevenue > 200000000) {
                  redirect = '/schedule-meeting-enterprise/'
                } else if (finalRevenue >= 10000000 && finalRevenue <= 200000000) {
                  redirect = '/schedule-meeting-mid-market/'
                }
              }

              router.push(redirect)
              setIsFormSubmitted(true)
            } else {
              setIsFormSubmitted(true)
              setIsSubmitting(false)
            }
          } else if (postSubmitAction?.type === 'redirect_url') {
            router.push(postSubmitAction?.value)
            setIsFormSubmitted(true)
          } else {
            setIsFormSubmitted(true)
          }

          window.sessionStorage.setItem('hs-form-data', JSON.stringify(formData))
        }}
      />
    </Wrapper>
  )
}

export default Form
