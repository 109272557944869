'use client'

import JoyChip from '@mui/joy/Chip'
import trackEvent from '../../lib/track-event'

const Chip = ({ children, triggersWhat, ...rest }) => {
  const props = {}

  if (rest?.slotProps?.action?.href && triggersWhat) {
    props.onClick = (event) => {
      Boolean(rest.onClick) && rest.onClick()

      const isInHeader = event.currentTarget.closest('header') !== null
      const isInFooter = event.currentTarget.closest('footer') !== null

      trackEvent('Chip Clicked', {
        'Chip Text': children,
        Trigger: rest?.slotProps?.action?.href ? 'Link' : triggersWhat,
        Destination: rest?.slotProps?.action?.href,
        Target: rest?.slotProps?.action?.target,
        Location: isInHeader ? 'Header' : isInFooter ? 'Footer' : 'Body',
      })
    }
  }

  return (
    <JoyChip {...rest} {...props}>
      {children}
    </JoyChip>
  )
}

export default Chip
